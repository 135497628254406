import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "../App.css";
import Nav from "../components/nav";
import Word from "../components/word";
import Loader from "../components/loader";
import Opinion from "../components/opinion";
import Opinions from "../components/opinions";
import ColumnPicker from "../components/columnPicker";
import MagicWand from "../components/svg/magicWand";
import TagsSummary from "../components/tagsSummary";
import TagsOpinions from "../components/tagsOpinions";
import { updateQueryStringParameter } from "../utils/updateQueryStringParameter";
import { shouldBeVisible } from "../utils/shouldBeVisible";
import TagsHeader from "../components/tagsHeader";
import TagsInput from "../components/tagsInput";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faListOl,
  faCirclePlay,
  faCircleStop,
  faHourglassStart,
  faCloudArrowDown,
  faFileCsv,
  faFileExcel,
  faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";
import { getProjectData } from "../utils/getProjectData";
import { getResponsesData } from "../utils/getResponsesData";
import SentimentAnalysisPreview from "../components/sentimentAnalysisPreview";
import SentimentAnalysisSummary from "../components/sentimentAnalysisSummary";
import SentimentAnalysisCost from "../components/sentimentAnalysisCost";
import RegisterForm from "../components/registerForm";
import TrialAlert from "../components/trialAlert";

const AnalyseSentiment = ({ steps, perPage }) => {
  const isInitialMount = useRef(true);
  const url = useLocation();
  const [autopilotInterval, setAutopilotInterval] = useState(3);
  const location = new URLSearchParams(url.search);
  const navigate = useNavigate();
  const [autopilotProgress, setAutopilotProgress] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isTimoutRunning, setIsTimeoutRunning] = useState(false);
  const [rowsView, setRowsView] = useState("not yet analysed");
  const [autopilot, setAutopilot] = useState(null);
  const autopilotRef = useRef(autopilot);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [responses, setResponses] = useState([]);
  const [sentimentSummary, setSentimentSummary] = useState([]);
  const [hash, setHash] = useState(
    url?.state?.initializedHash || location.get("hash")
  );
  const requestBatch = 3;
  const [pager, setPager] = useState([]);
  const [page, setPage] = useState(location.get("page") || 0);
  const [sortBy, setSortBy] = useState("count");
  const [filterSentiment, setFilterSentiment] = useState("all");
  const [sortOrder, setSortOrder] = useState(-1);
  const pageRef = useRef(page);
  const [view, setView] = useState([
    { name: "Opinions", icon: faComment },
    { name: "Summary", icon: faListOl },
    { name: "Cost", icon: faMoneyBillWave },
  ]);
  const [activeView, setActiveView] = useState(location.get("view") || 0);
  const [selectedTags, setSelectedTags] = useState(
    location.get("tags")
      ? location
          .get("tags")
          .split(",")
          .map((el) => parseInt(el))
      : []
  );
  const prevSelectedTags = useRef(selectedTags);
  const [registerModalShow, setRegisterModalShow] = useState(false);
  const registerModalShowRef = useRef(registerModalShow);
  const exportOptions = [
    { name: "Export opinions to CSV", type: "csv_column", icon: faFileCsv },
    {
      name: "Export opinions, summary to XLSX",
      type: "xlsx_column",
      icon: faFileExcel,
    },
  ];

  const isProjectRegistered = () => {
    return data.project?.email?.value?.length > 0;
  };
  const setGetParameterWithoutReloading = (params) => {
    const searchParams = new URLSearchParams(url.search);
    for (const [key, value] of Object.entries(params)) {
      searchParams.set(key, value);
    }
    navigate(`${url.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };
  const setViewParameterWithoutReloading = (view) => {
    console.log("setViewParameterWithoutReloading", view);
    const searchParams = new URLSearchParams(url.search);
    searchParams.set("view", view);
    console.log(
      "setViewParameterWithoutReloading",
      view,
      searchParams.toString()
    );
    navigate(`${url.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };
  const setTagsParameterWithoutReloading = (tags) => {
    const searchParams = new URLSearchParams(url.search);
    if (tags.length > 0) {
      searchParams.set("tags", tags.join(","));
    } else {
      searchParams.delete("tags");
    }
    navigate(`${url.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };
  const autopilotRequest = async () => {
    getAllDataFromDB((newResponses) => {
      if (autopilotRef.current === "on") {
        if (newResponses.count_all_ne === newResponses.count_analysed) {
          setAutopilot("off");
        } else {
          const id = setTimeout(autopilotRequest, 1000 * autopilotInterval);
          setTimeoutId(id);
        }
      }
    });
  };

  const startRequests = () => {
    if (autopilotRef.current === "on") {
      autopilotRequest();
    }
  };

  const stopRequests = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  const buildFilter = () => {
    const filter = {};
    if (selectedTags.length) {
      filter.tags = selectedTags;
    }
    console.log("filter", Object.keys(filter).length ? filter : undefined);
    return Object.keys(filter).length ? filter : undefined;
  };

  const getAllDataFromDB = async (callback) => {
    try {
      const [data, responses, summary] = await Promise.all([
        getProjectData(hash),
        getResponsesData(hash, page, perPage, buildFilter()),
        getSentimentSummary(hash, sortBy, sortOrder, filterSentiment),
      ]);
      setData(data);
      setAutopilot(data.project.autopilot);
      setResponses(responses);
      setSentimentSummary(summary);
      if (responses.count_all_ne > 0)
        setAutopilotProgress(
          Math.round(
            (responses.count_all_ne_analysed / (responses.count_all_ne || 0)) *
              100
          ).toString()
        );
      setLoading(false);
      if (callback) {
        callback(responses);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const analyseSentimentWithAi = async (hash, idRow) => {
    try {
      const xhrresponse = await fetch(
        process.env.REACT_APP_DJANGO_PROTOCOL +
          "://" +
          process.env.REACT_APP_DJANGO_HOST +
          process.env.REACT_APP_DJANGO_ANALYZE_SENTIMENT +
          `?` +
          "&hash=" +
          hash,
        {
          method: "POST",
          body: JSON.stringify({
            id: idRow,
          }),
        }
      );

      return xhrresponse.json();
    } catch (error) {
      // alert("Error during sentiment analysis");
      console.error("Error fetching data:", error);
    }
  };

  const getSentimentSummary = async (
    hash,
    sort_by,
    sort_order,
    filter_sentiment
  ) => {
    try {
      const xhrresponse = await fetch(
        process.env.REACT_APP_DJANGO_PROTOCOL +
          "://" +
          process.env.REACT_APP_DJANGO_HOST +
          process.env.REACT_APP_DJANGO_SUMMARIZE_SENTIMENT +
          `?` +
          "&hash=" +
          hash +
          "&sort_by=" +
          sort_by +
          "&sort_order=" +
          sort_order +
          "&filter_sentiment=" +
          filter_sentiment,
        {
          method: "GET",
        }
      );

      return xhrresponse.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const downloadFile = (type) => {
    window.location =
      process.env.REACT_APP_DJANGO_PROTOCOL +
      "://" +
      process.env.REACT_APP_DJANGO_HOST +
      process.env.REACT_APP_DJANGO_EXPORT_DATA +
      `?` +
      "&hash=" +
      hash +
      "&type=" +
      type;
  };

  const runAutopilot = async (status) => {
    try {
      setLoading(true);
      const xhrresponse = await fetch(
        process.env.REACT_APP_DJANGO_PROTOCOL +
          "://" +
          process.env.REACT_APP_DJANGO_HOST +
          process.env.REACT_APP_DJANGO_RUN_AUTOPILOT +
          `?` +
          "&hash=" +
          hash +
          "&status=" +
          status,
        {
          method: "GET",
          body: JSON.stringify(),
        }
      );
      const res = await xhrresponse.json();
      setLoading(false);
      if (!res.status) {
        return alert(res.msg);
      }

      setAutopilot(status);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllDataFromDB();
  }, []);

  useEffect(() => {
    autopilotRef.current = autopilot;
    if (autopilotRef.current === "on") {
      startRequests();
    }
    if (autopilotRef.current === "off") {
      stopRequests();
    }
  }, [autopilot]);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  useEffect(() => {
    return () => {
      if (timeoutId !== null) {
        clearInterval(timeoutId);
      }
    };
  }, [timeoutId]);

  useEffect(() => {
    if (!isInitialMount.current) {
      getSentimentSummary(hash, sortBy, sortOrder, filterSentiment).then(
        (data) => {
          setSentimentSummary(data);
        }
      );
    }
  }, [sortBy, sortOrder, filterSentiment]);

  useEffect(() => {
    if (!isInitialMount.current) {
      setLoading(true);
      if (prevSelectedTags.current != selectedTags) {
        setPage(0); // reset page when tags change
        prevSelectedTags.current = selectedTags;
      }
      setGetParameterWithoutReloading({
        view: activeView,
        tags: selectedTags,
        page: page,
      });
      getAllDataFromDB();
    }
    isInitialMount.current = false;
  }, [activeView, selectedTags, page]);

  return (
    <main className="">
      {loading && <Loader />}
      <RegisterForm
        showModal={registerModalShow}
        setShowModal={setRegisterModalShow}
        hash={hash}
        onRegister={() => {
          getAllDataFromDB(() =>
            runAutopilot(autopilot === "off" ? "on" : "off")
          );
        }}
      />
      <Sidebar
        activeStep="4"
        steps={steps}
        hash={hash}
        data={data.project}
        setLoading={setLoading}
      />
      <div className="p-3">
        <TrialAlert rowsLimit={data.project?.rows_limit || 0} hash={hash} />

        {data.project && (
          <div
            className="-sticky-top -bg-white -py-3 -px-3 pb-3"
            style={
              {
                // boxShadow: "rgb(238, 238, 238) 0px 0px 5px",
              }
            }
          >
            <div
              style={{ display: autopilot === "off" ? "none" : "" }}
              className="progress mb-3"
              role="progressbar"
            >
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                style={{
                  width: (autopilotProgress < 5 ? 5 : autopilotProgress) + "%",
                }}
              >
                {autopilotProgress + "%"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "15px",
              }}
            >
              <div className="">
                <div className="">
                  <strong style={{ fontSize: "125%" }}>
                    {responses.count_all_ne_analysed}
                  </strong>{" "}
                  / {responses.count_all_ne + " "} rows analysed
                  {autopilotProgress > 0 && (
                    <span
                      class="badge rounded-pill text-bg-warning"
                      style={{ marginLeft: "5px" }}
                    >
                      {autopilotProgress}%
                    </span>
                  )}
                </div>
              </div>
              <div
                className="subnavigation-bar"
                style={{ display: "flex", gap: "15px" }}
              >
                <div
                  className="autopilot-bar-with-progress"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <div
                    className="autopilot-and-spinner"
                    style={{
                      display: "flex",
                      gap: "15px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={autopilot === "off" ? { display: "none" } : {}}
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isProjectRegistered()) {
                          console.log("pokaz modal");
                          setRegisterModalShow(true);
                          return;
                        }
                        if (parseInt(autopilotProgress) === 100) {
                          alert("Analysis is already completed.");
                        } else {
                          runAutopilot(autopilot === "off" ? "on" : "off");
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={autopilot === "off" ? faCirclePlay : faCircleStop}
                      />{" "}
                      {autopilot === "off" ? "Begin" : "Stop"} automated
                      analysis
                    </button>
                  </div>
                </div>
                <div className="download-bar">
                  <div className="dropdown ">
                    <button
                      className="btn btn-success dropdown-toggle "
                      type="button"
                      data-bs-toggle="dropdown"
                    >
                      <FontAwesomeIcon icon={faCloudArrowDown} /> Export
                    </button>{" "}
                    <ul className="dropdown-menu">
                      {exportOptions.map((row, index) => (
                        <li key={"export_dropdown" + index}>
                          <a
                            className={"dropdown-item"}
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              downloadFile(row.type);
                            }}
                          >
                            <FontAwesomeIcon icon={row.icon} /> {" " + row.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ul className="nav nav-underline">
                {view.map((row, index) => {
                  return (
                    <li className="nav-item" key={"nav_item_" + index}>
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveView(index);
                          // setViewParameterWithoutReloading(index);
                        }}
                        className={
                          "nav-link" + (activeView == index ? " active" : "")
                        }
                        aria-current="page"
                        href="#"
                      >
                        <FontAwesomeIcon icon={row.icon} />
                        {row.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}

        {data.project?.columns.length && responses.count_all_ne > 0 && (
          <div className="results-container">
            {activeView == 0 && (
              <div className="sentiment-analysis-preview-container">
                <SentimentAnalysisPreview
                  activeColumns={data.project.columns.filter(
                    (col) => col.is_active
                  )}
                  tags={data.project.tags}
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                  responses={responses}
                  setResponses={setResponses}
                  hash={hash}
                  setTagsParameterWithoutReloading={
                    setTagsParameterWithoutReloading
                  }
                  analyseSentimentWithAi={analyseSentimentWithAi}
                  autopilot={autopilotRef.current}
                  // pager={pager}
                  page={page}
                  setPage={setPage}
                  setLoading={setLoading}
                  getAllDataFromDB={getAllDataFromDB}
                />
              </div>
            )}

            {activeView == 1 && (
              <div className="summary-container">
                <SentimentAnalysisSummary
                  sentimentSummary={sentimentSummary}
                  setActiveView={setActiveView}
                  setSelectedTags={setSelectedTags}
                  setSortBy={setSortBy}
                  setSortOrder={setSortOrder}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  allTags={data.project.tags}
                  filterSentiment={filterSentiment}
                  setFilterSentiment={setFilterSentiment}
                />
              </div>
            )}

            {activeView == 2 && (
              <div className="cost-container">
                <SentimentAnalysisCost
                  hash={hash}
                  data={data.project}
                  responses={responses}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </main>
  );
};

export default AnalyseSentiment;
