import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const SentimentAnalysisCost = ({ hash, data, responses }) => {
  const buyCredits =
    process.env.REACT_APP_BUY_CREDITS + "?external_key=" + hash;
  return (
    <div>
      <table className="table table-striped">
        <tbody>
          <tr>
            <td>Number of responses</td>
            <td>{responses.count_all}</td>
          </tr>

          <tr>
            <td>Number of not empty responses</td>
            <td>{responses.count_all_ne}</td>
          </tr>
          <tr>
            <td>Number of analysed responses</td>
            <td>{responses.count_all_ne_analysed}</td>
          </tr>
          <tr>
            <td>Number of tags</td>
            <td>{data.tags?.length || "-"}</td>
          </tr>
          <tr>
            <td>Average cost per sentiment analysis</td>
            <td>
              {responses.count_all_ne_analysed
                ? "$" +
                  (data.credit.usage / responses.count_all_ne_analysed).toFixed(
                    3
                  )
                : "-"}
            </td>
          </tr>
          <tr>
            <td>Total cost</td>
            <td>${data.credit.usage}</td>
          </tr>
          <tr>
            <td>Your available credit</td>
            <td>${(data.credit.available - data.credit.usage).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <a href={buyCredits} className="btn btn-warning m-3-" target="_blank">
          <FontAwesomeIcon icon={faCoins} className="" /> Buy more credits
        </a>
      </div>
    </div>
  );
};

export default SentimentAnalysisCost;
