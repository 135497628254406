import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListCheck, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const ColumnDropdown = ({
  columns,
  selectedColumns,
  setSelectedColumns,
  saveActiveColumn,
}) => {
  console.log("selectedColumns", selectedColumns);

  return (
    <>
      <button
        className="btn btn-primary dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <FontAwesomeIcon icon={faListCheck} /> Select questions to analyse
        {/* {selectedColumns.length > 0 &&
          columns.map((col, i) => {
            return col.is_visible && selectedColumns.includes(col.column_index)
              ? col.column_name
              : "";
          })} */}
      </button>
      <ul
        className="dropdown-menu  dropdown-menu-end -show"
        style={{ height: "400px", overflowY: "auto", maxWidth: "800px" }}
      >
        <li>
          <h6 className="dropdown-header">
            Suggested questions with longer answers
          </h6>
        </li>
        <Column
          columns={columns.filter(
            (col) => col.avg_length > 20 && col.uniqueness_ratio > 0.5
          )}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          saveActiveColumn={saveActiveColumn}
        />
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <h6 className="dropdown-header">All questions</h6>
        </li>
        <Column
          columns={columns}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          saveActiveColumn={saveActiveColumn}
        />
      </ul>
    </>
  );
};
const Column = ({
  columns,
  selectedColumns,
  setSelectedColumns,
  saveActiveColumn,
}) => {
  return columns.map((col, i) => {
    return (
      <li key={"question_" + i}>
        <a
          className={
            selectedColumns.includes(col.column_index)
              ? "dropdown-item active-"
              : "dropdown-item"
          }
          onClick={(e) => {
            e.preventDefault();
            // e.stopPropagation();
            setSelectedColumns((prev) => {
              saveActiveColumn(
                col.column_index,
                !prev.includes(col.column_index)
              );
              if (prev.includes(col.column_index)) {
                return prev.filter((c) => c !== col.column_index);
              }
              return prev.concat(col.column_index);
            });
          }}
          href="#"
        >
          {selectedColumns.includes(col.column_index) && (
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ marginRight: "5px", color: "#009b85" }}
            />
          )}
          {col.column_name}
        </a>
      </li>
    );
  });
};

export default ColumnDropdown;
