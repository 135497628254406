import React from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faFilter,
  faFilterCircleXmark,
  faMagicWandSparkles,
  faRemove,
} from "@fortawesome/free-solid-svg-icons";
import { getResponsesData } from "../utils/getResponsesData";
import { Badge, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
function SentimentAnalysisPreview({
  tags,
  setTagsParameterWithoutReloading,
  selectedTags,
  setSelectedTags,
  activeColumns,
  responses,
  setResponses,
  hash,
  analyseSentimentWithAi,
  autopilot,
  setLoading,
  page,
  setPage,
  getAllDataFromDB,
}) {
  const [pager, setPager] = useState([]);
  const isInProgress = "in_progress";

  const analyseSentimentHandler = (response) => {
    console.log("Analyse button clicked", response._id);
    setResponses((prev) => {
      return {
        ...prev,
        responses: prev.responses.map((r) => {
          if (r._id === response._id) {
            return {
              ...r,
              status: "in_progress",
            };
          }
          return r;
        }),
      };
    });
    analyseSentimentWithAi(hash, response._id).then((data) => {
      console.log("data", data);

      setResponses((prev) => {
        return {
          ...prev,
          count_analysed:
            prev.count_analysed < prev.total_count
              ? prev.count_analysed + 1
              : prev.count_analysed,
          responses: prev.responses.map((r) => {
            if (r._id === response._id) {
              return {
                ...r,
                status: "finished",
                sentiment: data,
              };
            }
            return r;
          }),
        };
      });
      if (data.status === false) {
        alert(data.msg);
        return;
      }
      getAllDataFromDB();
    });
  };

  useEffect(() => {
    const pages = Math.ceil(
      (responses.count_all_ne_analysed_filtered || responses.count_all_ne) /
        responses.perPage
    );
    const pagination = [];
    for (let i = 0; i < pages; i++) {
      pagination.push({ page: i + 1, active: i === responses.page });
    }

    setPager(pagination);
  }, [responses]);

  // const getResponseDataHelper = async (page) => {
  //   setLoading(true);
  //   getResponsesData(hash, page, responses.perPage).then((data) => {
  //     setResponses(data);
  //     setLoading(false);
  //   });
  // };

  const TagBadge = ({ id, children, title, bg, onClick }) => (
    <OverlayTrigger
      overlay={<Tooltip id={id}>{title}</Tooltip>}
      delay={{ show: 1000, hide: 400 }}
    >
      <Badge bg={bg} style={{ cursor: "pointer" }} onClick={onClick}>
        {children}
      </Badge>
    </OverlayTrigger>
  );
  console.log("activeColumns", activeColumns);
  return (
    activeColumns?.length > 0 && (
      <>
        {tags?.length > 0 && (
          <div
            className="table-navigation mb-2"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              gap: "10px",
            }}
          >
            <div>
              {responses.count_all_ne_analysed_filtered >= 0 && (
                <div>
                  <strong style={{ fontSize: "125%" }}>
                    {responses.count_all_ne_analysed_filtered}
                  </strong>{" "}
                  rows for{" "}
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "5px",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {tags.map((tag, tag_index) => {
                      return (
                        selectedTags.includes(tag_index) && (
                          <TagBadge
                            bg="secondary"
                            title="Click to remove this filter"
                            id={"tag-filter-" + tag_index}
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedTags((prev) => {
                                return prev.filter((t) => t !== tag_index);
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faRemove} /> {tag}
                          </TagBadge>
                        )
                      );
                    })}
                    {selectedTags.length > 1 && (
                      <TagBadge
                        bg="danger"
                        title="Click to remove all filters"
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedTags([]);
                        }}
                      >
                        <FontAwesomeIcon icon={faFilterCircleXmark} /> Remove
                        all
                      </TagBadge>
                    )}
                  </div>
                </div>
              )}
            </div>
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="secondary"
                size="sm"
              >
                <FontAwesomeIcon icon={faFilter} /> Add filter
                {/* Filter tags
                {selectedTags.length > 0 &&
                  " (" + selectedTags.length + " selected)"} */}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/* {selectedTags.length > 0 && (
                  <>
                    <Dropdown.Item
                      href="#"
                      className="text-danger text-right"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedTags([]);
                      }}
                    >
                      <FontAwesomeIcon icon={faFilterCircleXmark} /> Clear all
                      tags
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )} */}

                <Dropdown.Header>Add tag filter</Dropdown.Header>

                {tags.map((tag, tag_index) => {
                  return (
                    <Dropdown.Item
                      href="#"
                      style={{
                        opacity: selectedTags.includes(tag_index) ? 0.1 : 1,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!selectedTags.includes(tag_index)) {
                          setSelectedTags((prev) => {
                            return [...prev, tag_index];
                          });
                        }
                      }}
                    >
                      {tag}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        <div className="items">
          {responses.responses.map((response, response_index) => {
            return (
              <div
                key={"response_" + response._id}
                className="p-3 flex-fill sentiment-analysis-preview"
              >
                {response.status === isInProgress && (
                  <div className="spinner-border" role="status"></div>
                )}
                {response.status !== isInProgress && autopilot !== "on" && (
                  <div className="btn-analyse">
                    {/* <button
                  className="btn btn-primary "
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FontAwesomeIcon icon={faCog} />
                </button> */}
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        analyseSentimentHandler(response);
                      }}
                    >
                      <FontAwesomeIcon icon={faMagicWandSparkles} /> Analyse
                    </button>
                  </div>
                )}
                {activeColumns
                  .filter((column) => {
                    return (response.data[column.column_index] + "").trim()
                      .length;
                  })
                  .map((column) => {
                    return (
                      <div
                        key={"columns_" + column.column_index}
                        style={
                          response.status === isInProgress
                            ? { color: "rgba(0,0,0,0.3)" }
                            : {}
                        }
                      >
                        <label style={{ fontSize: "75%" }}>
                          {column.column_name}
                        </label>{" "}
                        <p>{response.data[column.column_index]}</p>
                      </div>
                    );
                  })}
                <div className="tags d-flex flex-wrap gap-1">
                  {Object.keys(response.sentiment || {}).map(
                    (tag, tag_index) => {
                      const color = {
                        "-1": "text-bg-danger",
                        0: "text-bg-warning",
                        1: "text-bg-success",
                      }[response.sentiment[tag]];

                      return (
                        <span
                          key={
                            "response_badge_" + response_index + "_" + tag_index
                          }
                          className={"badge " + color}
                          title={tag}
                        >
                          {tag}
                        </span>
                      );
                    }
                  )}

                  {response.sentiment &&
                    Object.keys(response.sentiment || {}).length === 0 && (
                      <span className="badge bg-secondary">No tags found</span>
                    )}
                </div>
              </div>
            );
          })}
          {[1, 2, 3, 4, 5, 6].map((el) => (
            <div className="p-3 flex-fill sentiment-analysis-preview transparent"></div>
          ))}
        </div>
        {pager.length > 0 && (
          <Pagination
            pager={pager}
            // getResponseDataHelper={getResponseDataHelper}
            setPage={setPage}
            page={page}
          />
        )}
      </>
    )
  );
}

export default SentimentAnalysisPreview;

const Pagination = ({ pager, setPage, page }) => {
  return (
    <ul className="pagination d-flex justify-content-end flex-wrap px-3">
      {pager.map((p, i) => {
        return (
          <li className="page-item" key={"pager_" + i}>
            <a
              className={"page-link" + (p.page - 1 === page ? " active" : "")}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                // getResponseDataHelper(p.page - 1);
                setPage(p.page - 1);
              }}
            >
              {p.page}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
