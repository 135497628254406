import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
// import "../App.css";
import Nav from "../components/nav";
import Word from "../components/word";
import Loader from "../components/loader";
import Opinion from "../components/opinion";
import Opinions from "../components/opinions";
import ColumnPicker from "../components/columnPicker";
import MagicWand from "../components/svg/magicWand";
import TagsSummary from "../components/tagsSummary";
import TagsOpinions from "../components/tagsOpinions";
import { updateQueryStringParameter } from "../utils/updateQueryStringParameter";
import { shouldBeVisible } from "../utils/shouldBeVisible";
import TagsHeader from "../components/tagsHeader";
import TagsInput from "../components/tagsInput";
import Sidebar from "../components/sidebar";
import DataTable from "../components/dataTable";

import { getProjectData } from "../utils/getProjectData";
import { getResponsesData } from "../utils/getResponsesData";

const SelectData = ({ steps, perPage }) => {
  const url = useLocation();
  const location = new URLSearchParams(url.search);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({});
  const [responses, setResponses] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [hash, setHash] = useState(
    url?.state?.initializedHash || location.get("hash")
  );
  console.log("hash", hash);

  const saveActiveColumn = async (column_index, column_is_active) => {
    setLoading(true);
    const xhrresponse = await fetch(
      process.env.REACT_APP_DJANGO_PROTOCOL +
        "://" +
        process.env.REACT_APP_DJANGO_HOST +
        process.env.REACT_APP_DJANGO_SET_PROJECT_DATA +
        `?` +
        "&hash=" +
        hash,
      {
        method: "POST",
        body: JSON.stringify({
          column_id: column_index,
          column_is_active: column_is_active,
        }),
      }
    );

    await xhrresponse.json();
    await getResponsesData(hash, 0, perPage).then((data) => {
      setResponses(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [data, responses] = await Promise.all([
          getProjectData(hash),
          getResponsesData(hash, 0, perPage),
        ]);
        console.log("projectResponse", data);
        setData(data);
        setSelectedColumns(
          data.project?.columns
            .filter((col) => col.is_active)
            .map((col) => col.column_index) || []
        );
        setResponses(responses);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <main className="">
      {loading && <Loader />}
      <Sidebar
        activeStep="2"
        steps={steps}
        hash={hash}
        data={data.project}
        setLoading={setLoading}
      />
      <div className="p-3">
        {data.project && responses && (
          <DataTable
            hash={hash}
            responsesData={responses}
            columns={data.project.columns}
            selectedColumns={selectedColumns}
            getResponseDataXHR={getResponsesData}
            setResponses={setResponses}
            setLoading={setLoading}
            setSelectedColumns={setSelectedColumns}
            saveActiveColumn={saveActiveColumn}
            steps={steps}
          />
        )}
      </div>
    </main>
  );
};

export default SelectData;
