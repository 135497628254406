import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

const NextButton = ({ children, steps, goToStep, hash }) => {
  const step = steps.filter((o) => o.step == goToStep)[0];
  const navigate = useNavigate();
  return (
    <Button
      variant="success"
      onClick={(e) => {
        e.preventDefault();
        navigate(step.url + "?hash=" + hash, {
          state: { initializedHash: hash },
        });
      }}
    >
      Next step: <strong>{step.title}</strong> {children}{" "}
      <FontAwesomeIcon icon={faCircleChevronRight} />
    </Button>
  );
};

export default NextButton;
