export const getProjectData = async (hash) => {
  const xhrresponse = await fetch(
    process.env.REACT_APP_DJANGO_PROTOCOL +
      "://" +
      process.env.REACT_APP_DJANGO_HOST +
      process.env.REACT_APP_DJANGO_GET_PROJECT_DATA_URL +
      `?` +
      "&hash=" +
      hash,
    {
      method: "GET",
    }
  );

  return xhrresponse.json();
};
