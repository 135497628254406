import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import Nav from "../components/nav";
import Word from "../components/word";
import Loader from "../components/loader";
import Opinion from "../components/opinion";
import Opinions from "../components/opinions";
import ColumnPicker from "../components/columnPicker";
import MagicWand from "../components/svg/magicWand";
import TagsSummary from "../components/tagsSummary";
import TagsOpinions from "../components/tagsOpinions";
import { updateQueryStringParameter } from "../utils/updateQueryStringParameter";
import { shouldBeVisible } from "../utils/shouldBeVisible";
import TagsHeader from "../components/tagsHeader";
import TagsInput from "../components/tagsInput";
import Sidebar from "../components/sidebar";
import NextButton from "../components/nextButton";
import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faTrash,
  faMagicWandSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { getProjectData } from "../utils/getProjectData";
import TrialAlert from "../components/trialAlert";

const SetTags = ({ steps }) => {
  const url = useLocation();
  const location = new URLSearchParams(url.search);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [changed, setChanged] = useState(false);
  const [tagsView, setTagsView] = useState("generate-with-ai");

  const [hash, setHash] = useState(
    url?.state?.initializedHash || location.get("hash")
  );

  const [tags, setTags] = useState([]);
  const [tagsToAnimate, setTagsToAnimate] = useState({});
  const saveTagsToDB = async (tags) => {
    setLoading(true);
    fetch(
      process.env.REACT_APP_DJANGO_PROTOCOL +
        "://" +
        process.env.REACT_APP_DJANGO_HOST +
        process.env.REACT_APP_DJANGO_SET_TAGS +
        `?` +
        "&hash=" +
        hash,
      {
        method: "POST",
        body: JSON.stringify({ tags }),
      }
    )
      .catch((e) => {
        console.log(e);
      })
      .then((xhrresponse) => {
        setLoading(false);
        setChanged(false);
      });
  };

  console.log("tagsView", tagsView);
  const initDataFromDB = async () => {
    try {
      const [data] = await Promise.all([getProjectData(hash)]);
      setData(data);
      if (data.project.tags?.length) setTags(data.project.tags);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    initDataFromDB();
  }, []);

  useEffect(() => {}, [tags]);

  useEffect(() => {
    console.log("data changed", data);
    if (data.project?.topics_extractor.status === "on") {
      getTopicsDuringAutopilot();
      setTimeout(initDataFromDB, 1000 * 5);
    }
    if (data.project?.tags?.length > 0) setTagsView("manual");
  }, [data]);

  const getTopicsDuringAutopilot = async () => {
    const xhrresponse = await fetch(
      process.env.REACT_APP_DJANGO_PROTOCOL +
        "://" +
        process.env.REACT_APP_DJANGO_HOST +
        process.env.REACT_APP_DJANGO_GET_TOPICS +
        `?` +
        "&hash=" +
        hash,
      {
        method: "GET",
      }
    );

    const json = await xhrresponse.json();
    console.log("tagstoanimate", json);
    setTagsToAnimate(json);
  };

  const generateTagsWithAI = async () => {
    setLoading(true);
    setData((prevData) => {
      return {
        ...prevData,
        project: {
          ...prevData.project,
          topics_extractor: {
            ...prevData.project.topics_extractor,
            status:
              prevData.project.topics_extractor.status == "on" ? "off" : "on",
          },
        },
      };
    });
    const req = await fetch(
      process.env.REACT_APP_DJANGO_PROTOCOL +
        "://" +
        process.env.REACT_APP_DJANGO_HOST +
        process.env.REACT_APP_DJANGO_GENERATE_TAGS_WITH_AI +
        `?` +
        "&hash=" +
        hash +
        "&status=" +
        (data.project.topics_extractor.status == "on" ? "off" : "on"),
      {
        method: "GET",
      }
    ).catch((e) => {
      console.log(e);
    });

    const json = await req.json();
    // const listOfAiTags = [];
    // json.topics.forEach((topic) => {
    //   topic.topic_level2_name.forEach((subtopic) => {
    //     listOfAiTags.push(topic.topic_level1_name + " > " + subtopic);
    //   });
    // });

    // setTags(listOfAiTags);
    // setChanged(true);

    setLoading(false);
  };

  return (
    <main className="">
      {loading && <Loader />}
      <Sidebar
        activeStep="3"
        steps={steps}
        hash={hash}
        data={data.project}
        setLoading={setLoading}
      />
      <div className="p-3">
        <TrialAlert rowsLimit={data.project?.rows_limit || 0} hash={hash} />
        <div className="pb-3">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {data.project?.topics_extractor.status === "on" && (
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <div className="spinner-grow text-dark" role="status"></div>
                  {data.project?.topics_extractor.status === "on" && (
                    <div>
                      {data.project?.topics_extractor.tier > 0
                        ? "Groupping tags on tier #" +
                          data.project?.topics_extractor.tier
                        : "Analysing provided data"}

                      <span
                        class="badge rounded-pill text-bg-warning"
                        style={{ marginLeft: "5px" }}
                      >
                        {tagsToAnimate.topics_total > 0
                          ? (
                              tagsToAnimate.topics_done /
                              tagsToAnimate.topics_total
                            ).toFixed(2) * 100
                          : 0}
                        %
                      </span>
                    </div>
                  )}
                </div>
              )}
              {/* <div>Use our predefined generic tags or type your own</div> */}
              {/* <div>We support 1 or 2 level tags. Each tag in a new line.</div> */}
            </div>

            {tags.length > 0 && !changed && (
              <div className="ms-auto">
                <NextButton steps={steps} goToStep={4} hash={hash}></NextButton>
              </div>
            )}
          </div>
        </div>

        {data.project?.topics_extractor.status === "on" && (
          <div className="row pt-1">
            <div className="col">
              <ConsoleDisplay messages={tagsToAnimate?.topics || []} />
            </div>
          </div>
        )}

        <div className="row p-1">
          {data.project?.topics_extractor.status === "off" &&
            tagsView !== "generate-with-ai" && (
              <div className="col">
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    id="tagsDefinition"
                    rows="10"
                    value={tags.join("\n")}
                    onChange={(e) => {
                      setChanged(true);
                      setTags(
                        e.target.value.length > 0
                          ? e.target.value.split("\n")
                          : []
                      );
                    }}
                  ></textarea>
                </div>

                <div
                  className="mb-3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      saveTagsToDB(tags.filter((tag) => tag !== ""));
                    }}
                  >
                    <FontAwesomeIcon icon={faSave} /> Save
                  </button>
                </div>
              </div>
            )}

          <div className="col text-center py-5">
            <Button variant="success" size="lg" onClick={generateTagsWithAI}>
              <FontAwesomeIcon icon={faMagicWandSparkles} />{" "}
              {data.project?.topics_extractor.status === "off"
                ? "Generate tags using AI"
                : "Stop generating process"}
            </Button>
            {data.project?.topics_extractor.status === "off" && (
              <div className="mt-3">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("manual");
                    setTagsView("manual");
                  }}
                  className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                >
                  I want to write tags myself
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default SetTags;

const ConsoleDisplay = ({ messages }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [randomStyle, setRandomStyle] = useState({});
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => {
        const newIndex = Math.floor(Math.random() * messages.length);
        return newIndex !== prevIndex
          ? newIndex
          : (newIndex + 1) % messages.length;
      });
      setRandomStyle({
        fontSize: `${Math.floor(Math.random() * (3 - 1 + 1) + 3)}rem`,
        height: "400px",
        display: "flex",
        alignItems: Math.random() < 0.5 ? "flex-start" : "flex-end",
        flexDirection:
          Math.random() < 0.25
            ? "row"
            : Math.random() < 0.5
            ? "row-reverse"
            : Math.random() < 0.75
            ? "column"
            : "column-reverse",
      });
    }, 800);

    return () => clearInterval(interval);
  }, [messages.length]);

  return (
    messages.length > 0 && (
      <div style={randomStyle}>{messages[currentMessageIndex]}</div>
    )
  );
};
