import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCartShopping,
  faArrowDown,
  faPaperPlane,
  faCog,
  faGift,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { track } from "@amplitude/analytics-browser";

const Sidebar = ({ steps, activeStep, hash, data, setLoading }) => {
  const { confirm } = window;
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  const activeColumns = data?.columns?.filter((c) => c.is_active).length;
  const buyCredits =
    process.env.REACT_APP_BUY_CREDITS + "?external_key=" + hash;
  const contactUs = process.env.REACT_APP_CONTACT_US + "?external_key=" + hash;
  const removeSentimentAnalysis = async () => {
    setLoading(true);
    fetch(
      process.env.REACT_APP_DJANGO_PROTOCOL +
        "://" +
        process.env.REACT_APP_DJANGO_HOST +
        process.env.REACT_APP_DJANGO_REMOVE_SENTIMENT_ANALYSIS +
        `?` +
        "&hash=" +
        hash,
      {
        method: "GET",
      }
    )
      .catch((e) => {
        console.log(e);
      })
      .then((xhrresponse) => {
        setLoading(false);
        window.location = window.location.toString();
      });
  };

  const isColumnActive = (step) => {
    return true; //narazie wycofuje sie
    console.log("step", step, data?.columns?.length > 0);
    if (step == 1) return true;
    if (step == 2) return data?.columns?.length > 0;
    if (step == 3) return activeColumns > 0;
    if (step == 4) return data?.tags?.length > 0;
  };
  console.log("data", data);
  return (
    <div>
      {data && data.is_test && (
        <div
          className="alert alert-info"
          style={{ marginBottom: 0, borderWidth: "0 0 1px 0", borderRadius: 0 }}
        >
          You are running in test mode. Requests are not being processed with
          AI. You will see sample responses.
        </div>
      )}

      {data && !data.can_prompt && (
        <div
          className="alert alert-danger"
          style={{ marginBottom: 0, borderWidth: "0 0 1px 0", borderRadius: 0 }}
        >
          You have no credits left. Please{" "}
          <a className="alert-link" href={buyCredits} target="_blank">
            <FontAwesomeIcon icon={faCartShopping} /> buy more credits
          </a>
        </div>
      )}
      <div
        className="px-3"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#042d3f",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // gap: "10px",
            // marginBottom: "20px",
            alignItems: "center",
            position: "relative",
          }}
        >
          <a href="/" className="-mb-5" style={{}}>
            <img
              src="https://app.startquestion.com/images/icons/symbol_webankieta.svg"
              class="projects-link__icon projects-link__icon--projects"
              alt=""
              aria-hidden="true"
              width="28px"
              height="28px"
            ></img>

            <span
              style={{
                // position: "absolute",
                // right: "5px",
                // bottom: "-7px",
                // textTransform: "uppercase",
                // fontSize: "10px",
                fontWeight: "normal",
                color: "#fff",
                textDecoration: "none",
              }}
            >
              {" "}
              Insightomat
            </span>
          </a>
          {/* <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setSidebarOpen(!sidebarOpen);
          }}
          style={
            !sidebarOpen
              ? {
                  position: "absolute",
                  right: "-70px",
                  background: "#fff",
                  padding: "20px",
                  top: "-16px",
                  boxShadow: "3px 5px 5px #ccc",
                  transition: "right 0.5s ease",
                }
              : {
                  transition: "right 0.5s ease",

                  // position: "absolute",
                  right: "0px",
                  // background: "#fff",
                  // border: "none",
                }
          }
        >
          <FontAwesomeIcon icon={faBars} />
        </a> */}
        </div>
        <ul className="steps">
          {steps &&
            steps.map((step, stepIndex) => (
              <li
                className={"nav-item"}
                key={"step_" + step.step}
                style={{
                  opacity:
                    !hash /*&& isColumnActive(step.step)*/ && step.step > 1
                      ? 0.25
                      : 1,
                }}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(hash ? step.url + "?hash=" + hash : "#");
                  }}
                  href="#"
                  className={` ${activeStep == step.step ? "active" : ""}`}
                  aria-current="page"
                >
                  {/* <FontAwesomeIcon icon={step.icon} /> */}
                  <span className="num">{step.step}</span>{" "}
                  <span>{step.title}</span>
                </a>
              </li>
            ))}
        </ul>

        <div style={{ width: "80px", textAlign: "right" }}>
          {data && (
            <>
              <div className="dropdown">
                <a
                  href="#"
                  className="text-white text-decoration-none dropdown-toggle"
                  data-bs-toggle="dropdown"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                  aria-expanded="false"
                >
                  <div>
                    {data && (
                      <>
                        {typeof data?.credit.available !== "undefined" && (
                          <strong>
                            {data.credit.available - data.credit.usage >= 0
                              ? ""
                              : "-"}
                            $
                            {Math.abs(
                              data.credit.available - data.credit.usage
                            ).toFixed(2)}
                          </strong>
                        )}
                      </>
                    )}
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-dark- text-small shadow">
                  <li>
                    <a
                      className="dropdown-item"
                      href={buyCredits}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faCartShopping} /> Buy credits
                    </a>
                  </li>
                  {/* <li>
                <a className="dropdown-item" href="#">
                  <FontAwesomeIcon icon={faGift} /> Get free credits
                </a>
              </li> */}
                  <li>
                    <a
                      className="dropdown-item"
                      style={{ color: "" }}
                      target="_blank"
                      href={contactUs}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} /> Contact us
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a
                      href="#"
                      className="dropdown-item link-danger"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          confirm(
                            "Are you sure you want to delete all analysed rows?\nThis action cannot be undone."
                          )
                        ) {
                          removeSentimentAnalysis();
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} /> Delete all analysed
                      rows
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
