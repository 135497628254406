import React from "react";
import { useState, useEffect } from "react";
import ColumnDropdown from "../components/columnDropdown";
import { getResponsesData } from "../utils/getResponsesData";
import NextButton from "./nextButton";
const DataTable = ({
  hash,
  responsesData,
  columns,
  selectedColumns,
  setResponses,
  setLoading,
  setSelectedColumns,
  saveActiveColumn,
  steps,
}) => {
  const [pager, setPager] = useState([]);
  const rows = responsesData.responses;
  const ignoreEmptyRows = true;
  const filteredColumns = columns.filter((col) =>
    selectedColumns.includes(col.column_index)
  );

  const getResponseDataHelper = async (page) => {
    setLoading(true);
    getResponsesData(hash, page, responsesData.perPage).then((data) => {
      setResponses(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    // pager initialization
    const pages = Math.ceil(responsesData.count_all_ne / responsesData.perPage);
    const pagination = [];
    for (let i = 0; i < pages; i++) {
      pagination.push({ page: i + 1, active: i === responsesData.page });
    }
    setPager(pagination);
  }, [rows]);

  return (
    <div className="">
      {/* <hr /> */}
      <div className="pb-3" style={selectedColumns.length > 0 ? {} : {}}>
        <div className="d-flex justify-content-between align-items-center">
          {selectedColumns.length > 0 && (
            <div className="">
              <strong style={{ fontSize: "125%" }}>
                {responsesData.count_all_ne}
              </strong>{" "}
              non empty rows match from total of {responsesData.count_all} rows
            </div>
          )}

          <div className="ms-auto">
            <ColumnDropdown
              columns={columns}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              saveActiveColumn={saveActiveColumn}
            />
          </div>
          <div className="ms-2">
            {selectedColumns.length > 0 && (
              <NextButton steps={steps} goToStep={3} hash={hash}></NextButton>
            )}
          </div>
        </div>
      </div>
      {selectedColumns.length > 0 && (
        <>
          <div className="table-responsive ">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  {filteredColumns.map((col) => (
                    <th key={"header_" + col.column_index}>
                      {col.column_name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {rows.map((row, index) => (
                  <tr key={"row_" + index}>
                    <td>
                      {index + 1 + responsesData.perPage * responsesData.page}
                    </td>
                    {filteredColumns.map((col, index2) => (
                      <td key={"row_" + index + "_col_" + index2}>
                        {row.data[col.column_index] || (
                          <span style={{ fontSize: "75%", color: "#ccc" }}>
                            empty row
                          </span>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="">
            <Pagination
              pager={pager}
              getResponseDataHelper={getResponseDataHelper}
            />
          </div>
        </>
      )}
    </div>
  );
};

const Pagination = ({ pager, getResponseDataHelper }) => {
  return (
    <ul className="pagination d-flex justify-content-end flex-wrap px-3">
      {pager.map((page, index) => {
        return (
          <li className="page-item" key={"pager_" + index}>
            <a
              className={"page-link" + (page.active ? " active" : "")}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                getResponseDataHelper(page.page - 1);
              }}
            >
              {page.page}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default DataTable;
