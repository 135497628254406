export const getResponsesData = async (hash, page, perPage, filter) => {
  const xhrresponse = await fetch(
    process.env.REACT_APP_DJANGO_PROTOCOL +
      "://" +
      process.env.REACT_APP_DJANGO_HOST +
      process.env.REACT_APP_DJANGO_GET_PROJECT_RESPONSES +
      `?` +
      "&hash=" +
      hash +
      "&page=" +
      page +
      "&perPage=" +
      perPage +
      (filter ? "&filter=" + JSON.stringify(filter) : ""),

    {
      method: "GET",
    }
  );

  return xhrresponse.json();
};
