import React from "react";

const TrialAlert = ({ rowsLimit, hash }) => {
  const buyCredits =
    process.env.REACT_APP_BUY_CREDITS + "?external_key=" + hash;
  return (
    rowsLimit > 0 && (
      <div className="alert alert-warning">
        Trial version is limited to maximum {rowsLimit} rows analysis.{" "}
        <a href={buyCredits} className="alert-link" target="_blank">
          Buy credits
        </a>{" "}
        to remove this limit.{" "}
      </div>
    )
  );
};

export default TrialAlert;
