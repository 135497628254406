import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "./loader";
import {
  faShip,
  faCircleChevronRight,
  faPaperPlane,
  faHand,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
const RegisterForm = ({ showModal, setShowModal, hash, onRegister }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const save = async () => {
    const xhrresponse = await fetch(
      process.env.REACT_APP_DJANGO_PROTOCOL +
        "://" +
        process.env.REACT_APP_DJANGO_HOST +
        process.env.REACT_APP_DJANGO_SET_PROJECT_EMAIL +
        `?` +
        "&hash=" +
        hash,
      {
        method: "POST",
        body: JSON.stringify({
          email: email,
        }),
      }
    );
    const res = await xhrresponse.json();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    await save();
    setLoading(false);
    handleClose();
    onRegister();
  };

  return (
    <>
      {loading && <Loading />}
      <Modal show={showModal} onHide={handleClose}>
        <Form onSubmit={handleSave}>
          <Modal.Header closeButton>
            <Modal.Title>Almost there 🤓</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="register-email-1">
              <Form.Label>Company email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@company-email.com"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Group>
            <p>
              We need your company email to assign the project. We might send
              you the confirmation link.
              {/* You will receive an email
          with a link to the project. */}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Start analysis <FontAwesomeIcon icon={faCircleChevronRight} />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default RegisterForm;
