import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../App.css";
import Nav from "../components/nav";
import Word from "../components/word";
import Loader from "../components/loader";
import Opinion from "../components/opinion";
import Opinions from "../components/opinions";
import ColumnPicker from "../components/columnPicker";
import MagicWand from "../components/svg/magicWand";
import TagsSummary from "../components/tagsSummary";
import TagsOpinions from "../components/tagsOpinions";
import { updateQueryStringParameter } from "../utils/updateQueryStringParameter";
import { shouldBeVisible } from "../utils/shouldBeVisible";
import TagsHeader from "../components/tagsHeader";
import TagsInput from "../components/tagsInput";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { getProjectData } from "../utils/getProjectData";

const UploadFile = ({ steps }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const url = useLocation();
  const location = new URLSearchParams(url.search);
  const [hash, setHash] = useState(
    url?.state?.initializedHash || location.get("hash")
  );
  const hiddenFileInput = useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSelectedFile(event.target.files[0]);
    const formData = new FormData();
    formData.append("file", event.target.files[0]); // 'selectedFile' is the file obtained from input

    try {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_DJANGO_PROTOCOL +
          "://" +
          process.env.REACT_APP_DJANGO_HOST +
          process.env.REACT_APP_DJANGO_UPLOAD_URL,
        {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      setLoading(false);
      if (res.file.hash) {
        // setHash(res.file.hash);
        const hash = res.file.hash;
        navigate("/select-data?hash=" + hash);
      }
      // Handle response...
    } catch (error) {
      console.log(error);
      // Handle error...
    }
  };

  return (
    <main className="">
      {loading && <Loader />}
      <Sidebar
        activeStep="1"
        steps={steps}
        hash={hash}
        // data={typeof data !== "undefined" ? data.project : {}}
        setLoading={setLoading}
      />
      <div className="p-3">
        <input
          type="file"
          className="form-control"
          ref={hiddenFileInput}
          id="inputGroupFile04"
          aria-describedby="inputGroupFileAddon04"
          aria-label="Upload"
          accept=".xls,.xlsx,.csv"
          onChange={handleSubmit}
          style={{ display: "none" }}
        />

        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "30px",
            textAlign: "center",
            margin: "70px 50px",
          }}
        >
          <h2 style={{}} className="display-6">
            Upload XLS/CSV raw survey results to{" "}
            <span style={{ fontWeight: 700 }}>analyze sentiment</span> with AI{" "}
          </h2>
          <button
            type="button"
            onClick={handleClick}
            className="btn btn-success btn-lg"
          >
            <FontAwesomeIcon icon={faCloudArrowUp} /> Select XLS/CSV file to
            start
          </button>
          <div className="-mt-3">
            <a
              href="/insightomat_sample_file.csv"
              className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
            >
              Download sample CSV file
            </a>
          </div>
          <div
            className="alert alert-light text-left"
            style={{ textAlign: "left" }}
          >
            AI will analyze provided content from the file. Operations handled
            by OpenAI. See{" "}
            <a
              href="https://www.startquestion.com/privacy-policy/"
              className="alert-link"
              target="_blank"
            >
              privacy policy
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UploadFile;
