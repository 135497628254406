import React, { useState, useEffect, useRef } from "react";
import { Button, Tooltip, OverlayTrigger, ProgressBar } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRankingStar,
  faCaretDown,
  faCaretUp,
  faArrowDownZA,
  faArrowUpAZ,
  faArrowUp19,
  faArrowDown91,
  faArrowUpWideShort,
  faArrowDownShortWide,
  faArrowUpShortWide,
  faComment,
} from "@fortawesome/free-solid-svg-icons";

const SentimentAnalysisSummary = ({
  sentimentSummary,
  setSortBy,
  setSortOrder,
  sortBy,
  sortOrder,
  setActiveView,
  setSelectedTags,
  allTags,
  filterSentiment,
  setFilterSentiment,
}) => {
  const [hiddenTags, setHiddenTags] = React.useState([]);
  const handleSort = (e, field) => {
    e.preventDefault();
    if (sortBy === field) {
      setSortOrder(sortOrder * -1);
    } else {
      setSortBy(field);
      setSortOrder(-1);
    }
  };
  const handleFilterSentiment = (e, field) => {
    e.preventDefault();
    setFilterSentiment(field);
  };
  const sortOptions = [
    // { name: "Tag", field: "level1_name" },
    { name: "Popularity", field: "count" },
    { name: "Sentiment", field: "average" },
    // { name: "Popularity x Sentiment", field: "product" },
  ];

  const filterSentimentOptions = [
    { name: "Total ranking", field: "all" },
    { name: "Only positive ranking", field: "positive" },
    { name: "Only negative ranking", field: "negative" },
  ];
  return (
    <div>
      {/* <h3 className="p-3">Tags Sentiment Summary</h3> */}
      <div className="summary-list">
        {/* {sentimentSummary.map((s, i) => {
          return (
            <div className="summary-list-row" key={"summary_list_" + i}>
              <div className="label">
                {s._id} ({s.count})
              </div>
              <div className="avg">{s.average.toFixed(2)}</div>
            </div>
          );
        })} */}

        {sentimentSummary.results.length > 0 && (
          <div>
            <div
              className="table-navigation mb-2"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div className="dropdown ">
                <button
                  className="btn btn-sm btn-secondary dropdown-toggle "
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  {
                    filterSentimentOptions.find(
                      (o) => o.field === filterSentiment
                    ).name
                  }
                </button>{" "}
                <ul className="dropdown-menu">
                  {filterSentimentOptions.map((option, index) => (
                    <li key={"filter_sentiment_dropdown" + index}>
                      <a
                        className={"dropdown-item"}
                        href="#"
                        title={option.name}
                        onClick={(e) => {
                          handleFilterSentiment(e, option.field);
                        }}
                      >
                        {option.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              {sentimentSummary.results.filter(
                (el) => el.level2_items.length > 0
              ).length > 0 && (
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setHiddenTags((prev) => {
                      if (prev.length > 0) {
                        return [];
                      }
                      return sentimentSummary.results.map((s) => s.level1_name);
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={hiddenTags.length > 0 ? faCaretUp : faCaretDown}
                  />{" "}
                  {hiddenTags.length > 0 ? "Hide" : "Show"} all subtags
                </button>
              )}
              <div className="dropdown ">
                <button
                  className="btn btn-sm btn-secondary dropdown-toggle "
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  Sort by
                  {sortOptions.find((o) => o.field === sortBy) && (
                    <>
                      {": "}
                      <FontAwesomeIcon
                        icon={
                          sortOrder < 0
                            ? faArrowUpWideShort
                            : faArrowDownShortWide
                        }
                      />{" "}
                      {sortOptions.find((o) => o.field === sortBy).name}
                    </>
                  )}
                </button>{" "}
                <ul className="dropdown-menu">
                  {sortOptions.map((option, index) => (
                    <li key={"sort_dropdown" + index}>
                      <a
                        className={"dropdown-item"}
                        href="#"
                        title={
                          "Sort by " +
                          option.name +
                          " " +
                          (sortOrder < 0 ? "ascending" : "descending")
                        }
                        onClick={(e) => {
                          handleSort(e, option.field);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={
                            sortOrder > 0
                              ? faArrowUpWideShort
                              : faArrowDownShortWide
                          }
                        />{" "}
                        {option.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <table className="table table-striped- table-hover">
              <thead>
                <tr>
                  <th className="text-center" style={{ width: "100%" }}>
                    Tag
                  </th>
                  <th className="text-center text-nowrap">Popularity</th>
                  <th className="text-center text-nowrap">Sentiment</th>
                  {/* <th className="text-center text-nowrap">PxS</th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {sentimentSummary.results.map((sentimentGroup, i) => {
                  return (
                    <>
                      <SentimentSummaryRow
                        tagIndexArray={() => {
                          let tags = [];
                          sentimentGroup.level2_items.forEach(
                            (tag, tag_index) => {
                              const tag_name =
                                sentimentGroup.level1_name +
                                sentimentSummary.levels_separator +
                                tag.level2_name;
                              if (allTags.indexOf(tag_name) !== -1) {
                                tags.push(allTags.indexOf(tag_name));
                              }
                            }
                          );
                          if (tags.length === 0) {
                            tags.push(
                              allTags.indexOf(sentimentGroup.level1_name)
                            );
                          }
                          return tags;
                        }}
                        setSelectedTags={setSelectedTags}
                        setActiveView={setActiveView}
                        name={sentimentGroup.level1_name}
                        count={sentimentGroup.count}
                        countPercentage={sentimentGroup.count_percentage}
                        average={sentimentGroup.average}
                        product={sentimentGroup.product}
                        level={1}
                        index={i}
                        isExpanded={hiddenTags.includes(
                          sentimentGroup.level1_name
                        )}
                        isHidden={false}
                        hasChildren={sentimentGroup.level2_items.length > 0}
                        onClick={(e) => {
                          e.preventDefault();
                          setHiddenTags((prev) => {
                            if (prev.includes(sentimentGroup.level1_name)) {
                              return prev.filter(
                                (v) => v !== sentimentGroup.level1_name
                              );
                            }
                            return prev.concat(sentimentGroup.level1_name);
                          });
                        }}
                      />
                      {sentimentGroup.level2_items.map(
                        (subsentimentGroup, j) => {
                          return (
                            <SentimentSummaryRow
                              tagIndexArray={() => {
                                const tag_name =
                                  sentimentGroup.level1_name +
                                  sentimentSummary.levels_separator +
                                  subsentimentGroup.level2_name;
                                return [allTags.indexOf(tag_name)];
                              }}
                              setSelectedTags={setSelectedTags}
                              setActiveView={setActiveView}
                              name={subsentimentGroup.level2_name}
                              count={subsentimentGroup.count}
                              countPercentage={
                                subsentimentGroup.count_percentage
                              }
                              average={subsentimentGroup.average}
                              product={subsentimentGroup.product}
                              hasChildren={false}
                              level={2}
                              index={j}
                              isExpanded={false}
                              isHidden={
                                !hiddenTags.includes(sentimentGroup.level1_name)
                              }
                            />
                          );
                        }
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        {sentimentSummary.results.length === 0 && (
          <div
            className="empty-screen p-5 text-center"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="m-5">
              <FontAwesomeIcon
                icon={faRankingStar}
                style={{ fontSize: "90px", color: "#ccc" }}
              />
            </div>
            <div style={{ color: "#ccc", fontSize: "30px" }}>
              Sentiment summary will be here once you analyse opinions
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const SentimentSummaryRow = ({
  name,
  count,
  countPercentage,
  average,
  product,
  level,
  index,
  isHidden,
  isExpanded,
  hasChildren,
  onClick,
  setSelectedTags,
  setActiveView,
  tagIndexArray,
}) => {
  return (
    <tr
      key={"summary_list_level_" + level + "_index_" + index}
      className={
        "no-border" + (level === 2 ? " table-warning" : " table-light")
      }
      style={isHidden ? { display: "none" } : {}}
    >
      <td
        onClick={onClick}
        style={isHidden ? {} : { cursor: hasChildren ? "pointer" : "" }}
      >
        <div
          style={{
            textAlign: "left",
          }}
        >
          {name}{" "}
          {hasChildren && (
            <FontAwesomeIcon icon={!isExpanded ? faCaretDown : faCaretUp} />
          )}
        </div>
        <div>
          <ProgressBar
            now={countPercentage + 10}
            variant={(() => {
              if (average < 0) {
                return "danger";
              } else if (average > 0) {
                return "success";
              }
              return "warning";
            })()}
            label={`${countPercentage.toFixed(1)}%`}
          />
        </div>
      </td>
      <td className="text-center">
        {count} / {countPercentage.toFixed(1)}%
      </td>
      <td className="text-center">{average.toFixed(2)}</td>
      {/* <td className="text-center">{product.toFixed(2)}</td> */}
      <td>
        <OverlayTrigger
          delay={{ show: 300, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip">
              Show opinions with tag: {name}
            </Tooltip>
          }
        >
          <Button
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              console.log("Show opinions with tag: ", tagIndexArray);
              setActiveView(0);
              setSelectedTags(tagIndexArray);
            }}
          >
            <FontAwesomeIcon icon={faComment} />
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default SentimentAnalysisSummary;
