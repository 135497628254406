import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Insights from "./pages/insights";
import Tags from "./pages/tags";
import UploadFile from "./pages/uploadFile";
import SelectData from "./pages/selectData";
import Sentiment from "./components/sentiment";
import SetTags from "./pages/setTags";
import AnalyseSentiment from "./pages/analyseSentiment";
import {
  faHome,
  faSquarePollVertical,
  faListCheck,
  faCogs,
  faCircleUser,
} from "@fortawesome/free-solid-svg-icons";
import { init } from "@amplitude/analytics-browser";
const AMPLITUDE_API_KEY = "c8e20bb605262ad580da6603f75f48ba";

function App() {
  const perPage = 25;
  const steps = [
    { step: "1", title: "File", url: "/", icon: faHome },
    {
      step: "2",
      title: "Data",
      url: "/select-data",
      icon: faListCheck,
    },
    { step: "3", title: "Topics", url: "/set-tags", icon: faCogs },
    {
      step: "4",
      title: "Summary",
      url: "/analyse-sentiment",
      icon: faSquarePollVertical,
    },
  ];
  const stepsComponents = {
    1: <UploadFile steps={steps} />,
    2: <SelectData steps={steps} perPage={perPage} />,
    3: <SetTags steps={steps} />,
    4: <AnalyseSentiment steps={steps} perPage={perPage} />,
  };

  useEffect(() => {
    init(AMPLITUDE_API_KEY);
  }, []);

  console.log("app", process.env.NODE_ENV);
  return (
    <Router>
      <Routes>
        {steps.map((step) => (
          <Route
            key={"route_" + step.step}
            path={step.url}
            element={stepsComponents[step.step] || <></>}
          />
        ))}

        {/* <Route path="/" element={<Upload />} /> */}
        {/* <Route path="/insights" element={<Insights />} /> */}
        {/* <Route path="/tags" element={<Tags />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
